<template>
  <div>
    <spinner-block v-model="progress">

      <div class="mb-3 alert alert-danger" v-if="data.is_warning">
        Есть расхождения в оплатах
      </div>

      <div class="row">
        <div class="col-6">


          <card-field title="Статус">
            <i class="fas fa-circle small"
               :title="data.is_active ? 'Активен' : 'Неактивен'"
               :class="data.is_active ? 'text-success' : 'text-danger'"></i>
            {{ data.subscription_status ? data.subscription_status.name : '—' }}
          </card-field>

          <card-field title="Цепочка подписок" v-if="can('module.subscription_chain.view')">
            <template v-if="data.subscription_chain_id">
              <router-link :to="{name: 'subscription-chain.detail', params: {id: data.subscription_chain_id}}"
                           target="_blank">
                #{{ data.subscription_chain_id }}
              </router-link>
            </template>
            <template v-else>—</template>
          </card-field>

          <card-field title="Клиент">
            <template v-if="data.client_id">
              <router-link :to="{name: 'client.detail', params: {id: data.client_id}}" target="_blank">
                #{{ data.client_id }} {{ data.client ? data.client.phone : '—' }}
              </router-link>
            </template>
            <template v-else>—</template>
          </card-field>

          <card-field title="Тариф">
            <template v-if="data.tariff_id">
              <router-link :to="{name: 'subscription-tariff.detail', params: {id: data.tariff_id}}" target="_blank">
                #{{ data.tariff_id }} {{ data.tariff ? data.tariff.name : '—' }}
              </router-link>
            </template>
            <template v-else>—</template>
          </card-field>

          <card-field title="Компания">
            <template v-if="data.business_company">
              <router-link :to="{name: 'business_company.detail', params: {id: data.business_company.id}}">
                {{ data.business_company.name || '—' }}
              </router-link>
            </template>
            <div v-else>Подписка не привязана к компании</div>
          </card-field>

          <card-field title="Стоимость согласно тарифу, руб" :modelValue="data.need_paid"/>
          <card-field title="Оплачено клиентом, руб" :modelValue="data.client_paid"/>
          <card-field title="Осталось бесплатных вызовов">
            {{ data.free_calls_count }} из доступных {{ data.tariff ? data.tariff.free_calls_count : '—' }}
          </card-field>

          <card-field title="Максимальное количество человек в подписке">
            {{ data.max_peoples }}
          </card-field>


        </div>
        <div class="col-6">
          <card-field title="Дата активации" :modelValue="toTime(data.activated_at)"/>
          <card-field title="Дата деактивации" :modelValue="toTime(data.deactivated_at)"/>
          <card-field title="Причина деактивации" :modelValue="data.deactivation_reason"/>

          <card-field class="mt-5" title="Дата отмены продления подписки" :modelValue="toTime(data.unsubscribe_at)"/>
          <card-field title="Дата когда подписка должна быть продлена"
                      :modelValue="toTime(data.should_be_prolonged_at)"/>
          <card-field title="Было ли отправлено уведомление о продлении подписки"
                      :modelValue="data.is_resume_notified ? 'Да' : 'Нет'"/>
        </div>
      </div>

      <div class="mt-3 row" v-if="can('module.subscription.update')">
        <div class="col-6">
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="unsubscribe()"
                    :disabled="!data.should_be_prolonged_at || !data.is_active">
              Остановить продление подписки
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="resumeSubscribe()"
                    :disabled="!!data.should_be_prolonged_at || !data.is_active">
              Восстановить продление подписки
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="closeSubscription()" :disabled="!data.is_active">
              Отменить подписку
            </button>
          </div>
          <div>
            <button-edit @click="$router.push({name: 'subscription.edit', params: {id: data.id}})"
                         v-if="can('module.subscription.update')"/>
          </div>
        </div>
        <div class="col-6">
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="recalc()">
              Пересчитать оплаченную стоимость
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="$refs.changeAmountModal.openModal(data.id)"
                    :disabled="!data.is_active">
              Изменить стоимость подписки
            </button>
            <subscription-change-amount-modal ref="changeAmountModal" @update="$emit('change')"/>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="returnPayment()"
                    :disabled="!data.client_paid || data.client_paid <= 0">
              Вернуть деньги за подписку
            </button>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="$refs.payModal.openModal(data.id)"
                    :disabled="!(data.client_paid < data.need_paid)">
              Оплатить подписку
            </button>
            <subscription-pay-modal ref="payModal" @update="$emit('change')"/>
          </div>
          <div>
            <button class="btn btn-secondary mb-3" @click.prevent="markSuccessWarning()" :disabled="!data.is_warning">
              Пометить оплату успешной
            </button>
          </div>
        </div>


      </div>


    </spinner-block>
  </div>
</template>

<script>


import SubscriptionChangeAmountModal from "@/views/Subscription/Block/SubscriptionChangeAmountModal";
import SubscriptionPayModal from "@/views/Subscription/Block/SubscriptionPayModal";

export default {
  components: {SubscriptionPayModal, SubscriptionChangeAmountModal},
  data() {
    return {
      progress: false,
    }
  },
  props: {
    data: Object
  },
  mounted() {
  },
  methods: {
    unsubscribe() {
      $.alertConfirm({
        content: 'По окончании даты деактивации подписка на новый период оформлена не будет. Деньги на новый период списаны не будут. Текущая подписка будет действовать до окончания даты деактивации. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription/unsubscribe', {id: this.data.id}, () => {
            this.$emit('change')
          }, e => this.progress = e)
        }
      });
    },
    resumeSubscribe() {
      $.alertConfirm({
        content: 'По окончании даты деактивации будет оформлена новая подписка на новый период действия. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription/resume-subscribe', {id: this.data.id}, () => {
            this.$emit('change')
          }, e => this.progress = e)
        }
      });
    },
    closeSubscription() {
      $.alertConfirm({
        content: 'Подписка будет завершена, как если бы завершился её срок действия. Оплата возвращена не будет. Действие необратимо. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription/close-subscription', {id: this.data.id}, () => {
            this.$emit('change')
          }, e => this.progress = e)
        }
      });
    },
    recalc() {
      axiosGetValue('/api/admin/subscription/recalc', {id: this.data.id}, () => {
        this.$emit('change')
      }, e => this.progress = e)
    },
    returnPayment() {
      $.alertConfirmForce({
        content: 'Оплаченные деньги за подписку будут возвращены на карту клиента. Действие необратимо. Для продолжения введите слово "Подтверждаю" в поле ниже.'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription/return-payment', {id: this.data.id}, () => {
            this.$emit('change')
          }, e => this.progress = e)
        }
      });

    },
    markSuccessWarning() {
      $.alertConfirm({
        content: 'Проблема с оплатой подписки будет отмеченна как урегулированная. Продолжить?'
      }, {
        ok: () => {
          axiosGetValue('/api/admin/subscription/mark-success-warning', {id: this.data.id}, () => {
            this.$emit('change')
          }, e => this.progress = e)
        }
      });
    },
  },
}
</script>
